import React from 'react';
import { Layout, Menu } from 'antd';
import {
  LoginOutlined,
  LogoutOutlined,
  HeartOutlined,
  AimOutlined,
} from '@ant-design/icons';
import { logout } from '../actions/auth';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import history from '../history';
import style from './CustomHeader.module.css';

const { Header } = Layout;

class CustomHeader extends React.Component {
  render() {
    return (
      <Header>
        <div className='logo' />
        <Menu
          theme='dark'
          mode='horizontal'
          selectedKeys={[this.props.currentRoute]}
        >
          {this.props.isAuthenticated ? (
            <>
              <Menu.Item
                icon={<LogoutOutlined />}
                key='/login'
                onClick={this.props.logout}
              >
                {/* onClick={} */}
                <Link to='/login'>Logout</Link>
              </Menu.Item>

              <Menu.Item
                style={{ marginLeft: '50px' }}
                icon={<AimOutlined />}
                key='/pheno_screens'
                onClick={() => {
                  history.push('/pheno_screens');
                }}
              >
                <Link to='/pheno_screens'>Phenotypic Cell-Seq Maps</Link>
              </Menu.Item>

              <Menu.Item
                icon={<HeartOutlined />}
                key='/sli'
                onClick={() => history.push('/sli')}
              >
                <Link to='/sli'>Fitness Maps</Link>
              </Menu.Item>
            </>
          ) : (
            <Menu.Item icon={<LoginOutlined />} key='/login'>
              <Link to='/login'>Login</Link>
            </Menu.Item>
          )}
        </Menu>
        <div
          style={{
            position: 'absolute',
            right: '50px',
            top: '5px',
            zIndex: '5',
          }}
        >
          <img
            style={{ width: '200px' }}
            alt='Genentech Logo'
            src='https://baysfuture.org/wp-content/uploads/2019/01/logo-genentech-e1546993248465.png'
          />
          <br />
          <img
            style={{ width: '200px' }}
            alt='Scenic Logo'
            src='https://www.scenicbiotech.com/user-files/themes/scenicbiotech/dist/svg/logo.svg'
          />
          <div className={style.circle_animation_one}></div>
          <div className={style.circle_animation_two}></div>
        </div>
      </Header>
    );
  }
}

const MapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token != null,
    currentRoute: state.screens.current_route,
  };
};

export default connect(MapStateToProps, { logout })(CustomHeader);
