import {
    AUTH_START,
    AUTH_SUCCESS,
    AUTH_FAIL,
    AUTH_LOGOUT,
} from '../actions/types';
import { updateObject } from './utility';

const initalState = {
    token: null,
    error: null,
    loading: false
}

const authStart = (state, action) => {
     return updateObject(state, {
        loading: true,
        error: null
     })
}

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.payload,
        error: null,
        loading: false
    })
}

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.payload,
        loading: false
    })
}

const authLogout = (state, action) => {
    return updateObject(state, {
        token: null
    })
}

const authReducer = (state=initalState, action) => {
    switch (action.type) {
        case AUTH_START:
            return authStart(state, action);
        case AUTH_SUCCESS:
            return authSuccess(state, action);
        case AUTH_FAIL:
            return authFail(state, action);
        case AUTH_LOGOUT:
            return authLogout(state, action);
        default:
            return state;
    }
}

export default authReducer;