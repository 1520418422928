import React, { lazy, Suspense } from 'react';
import 'antd/dist/antd.css';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import history from '../history';

import { Layout } from 'antd';
import CustomHeader from '../containers/CustomHeader';
import { connect } from 'react-redux';
import { authCheckState } from '../actions/auth';
import PrivateRoute from './PrivateRoute';

// import Main from './pheno_screens/Main';
// import Login from '../containers/Login';
// import MainSli from './sli_screens/MainSli';

const Main = lazy(() => import('./pheno_screens/Main'));
const MainSli = lazy(() => import('./sli_screens/MainSli'));
const Login = lazy(() => import('../containers/Login'));
const PhenoSetOperation = lazy(() => import('./pheno_set_operations/Main'));
// const Cluster = lazy(() => import('./pheno_screen_cluster/Cluster'))

class App extends React.Component {
  componentDidMount = () => {
    this.props.onTryAutoSignup();
  };

  render() {
    return (
      <Router history={history}>
        <Layout>
          <CustomHeader {...this.props} />
          <Switch>
            <Route
              exact
              path='/'
              render={() => {
                return this.props.isAuthenticated ? (
                  <Redirect to='/pheno_screens' />
                ) : (
                  <Redirect to='/login' />
                );
              }}
            />
            <Suspense fallback={<div></div>}>
              <Route path='/login' exact component={Login} />
              <PrivateRoute
                path='/pheno_screens'
                exact
                component={Main}
                isLoggedIn={this.props.isAuthenticated}
              />
              {/* <PrivateRoute path='/pheno_screens/cluster' exact component={Cluster} isLoggedIn={this.props.isAuthenticated}/> */}
              <PrivateRoute
                path='/sli'
                exact
                component={MainSli}
                isLoggedIn={this.props.isAuthenticated}
              />
              <PrivateRoute
                path='/set-operations'
                exact
                component={PhenoSetOperation}
                isLoggedIn={this.props.isAuthenticated}
              />
            </Suspense>
          </Switch>
        </Layout>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(authCheckState()),
    // logout: () => dispatch(logout())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

/* <Route path="/" exact component={Main} /> */
