import {
    AUTH_START,
    AUTH_SUCCESS,
    AUTH_FAIL,
    AUTH_LOGOUT,
} from './types';

import screens from '../api/screens';
import history from '../history'


export const authStart = () => {
    return {
        type: AUTH_START
    }
}

export const authSuccess = (token) => {
    return {
        type: AUTH_SUCCESS,
        payload: token
    }
}

export const authFail = (error) => {
    return {
        type: AUTH_FAIL,
        payload: error
    }
}

export const logout = () => {
    // localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    return {
        type: AUTH_LOGOUT
    };
}
 
export const checkAuthTimeout = (expirationTime) => {
    return (dispatch) => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000)
    };
}

export const authLogin = (username, password) => {
    return (dispatch) => {
        dispatch(authStart());
        screens.post('/api/rest-auth/login/', {
            username,
            password
        })
        .then(res => {
            const token = res.data.key;
            const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
            localStorage.setItem('token', token);
            localStorage.setItem('expirationDate', expirationDate);
            dispatch(authSuccess(token));
            dispatch(checkAuthTimeout(3600));
            history.push('/pheno_screens')
        })
        .catch( (err) => {
            dispatch(authFail(err))
        })
    }
}

export const authCheckState = () => {
    return (dispatch, getState) => {
        const token = localStorage.getItem('token')
        if (token === null) {
            dispatch(logout());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date() ) {
                dispatch(logout());
            } else {
                dispatch(authSuccess(token));
                dispatch(checkAuthTimeout( (expirationDate.getTime() - new Date().getTime()) / 1000));
                history.push('/pheno_screens')
            }
        }
    }
}