import {
  // NAVIGATION
  CURRENT_ROUTE,
  AJAX_START,
  AJAX_END,

  // PHENOTYPIC SCREENS
  FETCH_SCREENS,
  SET_NAME_SELECTED_SCREEN,
  FETCH_SCREEN_DATA,
  SELECTED_GENE,
  UNSELECTED_GENE,
  SHOW_GENE_PROFILES,
  FETCH_GENE_PROFILE,
  CLEAR_STATE,
  MANY_SELECTED_GENES,
  MANY_UNSELECTED_GENES,
  COMPARE_WITH,
  GET_COMPARED_DATA,
  REMOVE_COMPARED_DATA,
  FETCH_UNIQ_SCORE,
  SET_COLOR_FISHTAIL,
  SET_SIG_THRESHOLD,
  SHOW_SIG_ONLY,
  GET_EXPRESSION_DATA,
  SET_UNIQ_SCORE_UNIVERSE,
  SET_UNIQ_SCORE_VIZ,
  FETCH_PUBLIC_MAP_DESCR,
  REMOVE_GENE_PROFILES,
  SET_SELECTED_GENE,
  FETCH_GENE_INFO_ENSEMBL,
  REMOVE_GENE_INFO_ENSEMBL,
  GET_SET_OPERATION,
  CLEAR_SET_OPERATION,
  GET_UNIQUE_INSERTIONS,
  UPDATE_SET_OPERATION,
  SET_UNIQ_THR_SET_OPERATION,

  // SYNTHETIC LETHALITY
  FETCH_SLI_SCREENS,
  FETCH_SLI_CONTROLS,
  SET_NAME_SELECTED_SLI_SCREEN,
  SET_NAME_SELECTED_SLI_CONTROL,
  FETCH_SLI_SCREENS_DATA,
  FETCH_SLI_CONTROLS_DATA,
  SELECTED_SLI_GENE,
  MANY_SELECTED_SLI_GENES,
  UNSELECTED_SLI_GENE,
  MANY_UNSELECTED_SLI_GENES,
  SET_FILTER_ON_FISHER,
  SET_SIG_THRESHOLD_SLI,
  SET_MIN_N_CTR,
  SET_SPECIAL_QUERY,
  FETCH_SLI_FISHER_TABLE,
  GET_UNIQ_SCORE_SLI,
  SET_SLI_RESULT_VIEW,
  FETCH_GENE_INFO_ENSEMBL_SLI,
  SET_SELECTED_GENE_SLI,
  REMOVE_GENE_INFO_ENSEMBL_SLI,
  FETCH_GENE_PROFILE_SLI,
  GET_ESSENTIAL_GENES,
} from '../actions/types';
import { mapKeys, uniq } from 'lodash';
// import { useImperativeHandle } from 'react';

const initialState = {
  current_route: '',

  loading: false,

  screens: {},
  screens_named: {},
  name_selected_screen: null,
  selected_screen: {},
  uniq_score_selected_screen: {},
  expression: {},
  show_sig_only: false,
  color_plot: null,
  selected_genes: [],
  show_profiles: false,
  gene_profiles: {},
  public_descr: {},
  compare_with: false,
  compared_mi: null,
  sig_thr: 0.05,
  uniq_score_include_public: true,
  uniq_score_include_scenic: true,
  uniq_score_universe: [],
  uniq_score_viz: null,
  selected_gene_focus: null,
  gene_focus_info: {},
  essential_genes: [],
  pheno_set_operation: {},
  pheno_set_operation_filtered: {
    positive_regulators: [],
    negative_regulators: [],
  },
  uniq_thr_set_operation: null,

  sli_screens: {},
  sli_screens_controls: {},
  sli_name_selected_screen: null,
  sli_name_selected_control: null,
  sli_selected_screen_data: {},
  sli_selected_control_data: {},
  selected_sli_genes: [],
  sli_filter_on_fisher: 'pval',
  sli_fisher_data: {},
  sig_thr_sli: 0.05,
  sli_min_n_ctr: '4',
  sli_special_query: '',
  uniq_score_sli: {},
  uniq_score_include_public_sli: false,
  sli_result_view: 'sliGeneFocus',
  sli_gene_focus_info: {},
  sli_selected_gene_focus: null,
  sli_gene_profiles: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CURRENT_ROUTE:
      return {
        ...state,
        current_route: action.payload,
      };

    case AJAX_START:
      return {
        ...state,
        loading: true,
      };

    case AJAX_END:
      return {
        ...state,
        loading: false,
      };

    // PHENOTYPIC SCREENS REDUCERS
    case FETCH_SCREENS:
      return {
        ...state,
        screens: { ...state.screens, ...mapKeys(action.payload, 'screen_id') },
        screens_named: {
          ...state.screens_named,
          ...mapKeys(action.payload, 'screen_name'),
        },
      };
    case SET_NAME_SELECTED_SCREEN:
      return {
        ...state,
        name_selected_screen: action.payload,
      };
    case FETCH_SCREEN_DATA:
      return {
        ...state,
        selected_screen: action.payload,
      };
    case FETCH_PUBLIC_MAP_DESCR:
      return {
        ...state,
        public_descr: action.payload,
      };
    case FETCH_UNIQ_SCORE:
      return {
        ...state,
        uniq_score_selected_screen: { ...action.payload },
      };
    case SET_UNIQ_SCORE_UNIVERSE:
      return {
        ...state,
        uniq_score_include_public: action.payload.includePublic,
        uniq_score_include_scenic: action.payload.includeScenic,
        uniq_score_universe: action.payload.selectedScreens,
      };
    case SET_UNIQ_SCORE_VIZ:
      return {
        ...state,
        uniq_score_viz: action.payload,
      };
    case GET_EXPRESSION_DATA:
      return {
        ...state,
        expression: { ...action.payload },
      };
    case SELECTED_GENE:
      return {
        ...state,
        selected_genes: uniq([...state.selected_genes, action.payload]),
      };
    case SET_SIG_THRESHOLD:
      return {
        ...state,
        sig_thr: action.payload,
      };
    case SHOW_SIG_ONLY:
      return {
        ...state,
        show_sig_only: action.payload,
      };
    case MANY_SELECTED_GENES:
      return {
        ...state,
        selected_genes: uniq([...state.selected_genes, ...action.payload]),
      };
    case UNSELECTED_GENE:
      const gene_idx = state.selected_genes.indexOf(action.payload);
      const arr = [...state.selected_genes];
      arr.splice(gene_idx, 1);
      return {
        ...state,
        selected_genes: arr,
      };

    case MANY_UNSELECTED_GENES:
      return {
        ...state,
        selected_genes: [],
      };
    case SHOW_GENE_PROFILES:
      return {
        ...state,
        show_profiles: action.payload,
      };
    case FETCH_GENE_PROFILE:
      return {
        ...state,
        gene_profiles: { ...state.gene_profiles, ...action.payload },
      };
    case SET_COLOR_FISHTAIL:
      return {
        ...state,
        color_plot: action.payload,
      };
    case COMPARE_WITH:
      return {
        ...state,
        compare_with: action.payload,
      };
    case CLEAR_STATE:
      return {
        ...initialState,
      };
    case GET_COMPARED_DATA:
      return {
        ...state,
        compared_mi: action.payload,
      };
    case REMOVE_COMPARED_DATA:
      return {
        ...state,
        compared_mi: null,
      };
    case REMOVE_GENE_PROFILES:
      return {
        ...state,
        gene_profiles: {},
      };
    case FETCH_GENE_INFO_ENSEMBL:
      return {
        ...state,
        gene_focus_info: action.payload,
      };
    case SET_SELECTED_GENE:
      return {
        ...state,
        selected_gene_focus: action.payload,
      };
    case REMOVE_GENE_INFO_ENSEMBL:
      return {
        ...state,
        gene_focus_info: {},
      };
    case GET_ESSENTIAL_GENES:
      return {
        ...state,
        essential_genes: action.payload,
      };
    case GET_SET_OPERATION:
      return {
        ...state,
        pheno_set_operation: action.payload,
        pheno_set_operation_filtered: action.payload,
      };
    case CLEAR_SET_OPERATION:
      return {
        ...state,
        pheno_set_operation: {},
      };
    case UPDATE_SET_OPERATION:
      return {
        ...state,
        pheno_set_operation_filtered: {
          ...state.pheno_set_operation,
          ...action.payload,
        },
      };
    case SET_UNIQ_THR_SET_OPERATION:
      return {
        ...state,
        uniq_thr_set_operation: action.payload,
      };

    // SYNTHETIC LETHALITY REDUCERS
    case FETCH_SLI_SCREENS:
      return {
        ...state,
        sli_screens: { ...mapKeys(action.payload, 'screen_name') },
      };
    case FETCH_SLI_CONTROLS:
      return {
        ...state,
        sli_screens_controls: { ...mapKeys(action.payload, 'screen_name') },
      };
    case SET_NAME_SELECTED_SLI_SCREEN:
      return {
        ...state,
        sli_name_selected_screen: action.payload,
      };
    case SET_NAME_SELECTED_SLI_CONTROL:
      return {
        ...state,
        sli_name_selected_control: action.payload,
      };
    case FETCH_SLI_SCREENS_DATA:
      return {
        ...state,
        sli_selected_screen_data: { ...action.payload },
      };
    case FETCH_SLI_CONTROLS_DATA:
      return {
        ...state,
        sli_selected_control_data: { ...action.payload },
      };
    case SELECTED_SLI_GENE:
      return {
        ...state,
        selected_sli_genes: uniq([...state.selected_sli_genes, action.payload]),
      };
    case UNSELECTED_SLI_GENE:
      const sli_gene_idx = state.selected_sli_genes.indexOf(action.payload);
      const sli_arr = [...state.selected_sli_genes];
      sli_arr.splice(sli_gene_idx, 1);
      return {
        ...state,
        selected_sli_genes: sli_arr,
      };
    case MANY_SELECTED_SLI_GENES:
      return {
        ...state,
        selected_sli_genes: uniq([
          ...state.selected_sli_genes,
          ...action.payload,
        ]),
      };
    case MANY_UNSELECTED_SLI_GENES:
      return {
        ...state,
        selected_sli_genes: [],
      };
    case SET_FILTER_ON_FISHER:
      return {
        ...state,
        sli_filter_on_fisher: action.payload,
      };
    case SET_SIG_THRESHOLD_SLI:
      return {
        ...state,
        sig_thr_sli: action.payload,
      };
    case SET_MIN_N_CTR:
      return {
        ...state,
        sli_min_n_ctr: action.payload,
      };
    case SET_SPECIAL_QUERY:
      return {
        ...state,
        sli_special_query: action.payload,
      };
    case FETCH_SLI_FISHER_TABLE:
      return {
        ...state,
        sli_fisher_data: action.payload,
      };
    case GET_UNIQ_SCORE_SLI:
      return {
        ...state,
        uniq_score_sli: action.payload,
      };
    case SET_SLI_RESULT_VIEW:
      return {
        ...state,
        sli_result_view: action.payload,
      };
    case FETCH_GENE_INFO_ENSEMBL_SLI:
      return {
        ...state,
        sli_gene_focus_info: action.payload,
      };
    case SET_SELECTED_GENE_SLI:
      return {
        ...state,
        sli_selected_gene_focus: action.payload,
      };
    case REMOVE_GENE_INFO_ENSEMBL_SLI:
      return {
        ...state,
        sli_gene_focus_info: {},
      };
    case FETCH_GENE_PROFILE_SLI:
      return {
        ...state,
        sli_gene_profiles: { ...state.sli_gene_profiles, ...action.payload },
      };
    default:
      return state;
  }
};
