// via ssh
// NAVIGATION
export const CURRENT_ROUTE = 'CURRENT_ROUTE';

// UX
export const AJAX_START = 'AJAX_START';
export const AJAX_END = 'AJAX_END';

// PHENOTYPIC SCREENS
export const FETCH_SCREENS = 'fetchScreens';
export const SET_NAME_SELECTED_SCREEN = 'SET_NAME_SELECTED_SCREEN';
export const FETCH_SCREEN_DATA = 'fetchScreenData';
export const FETCH_UNIQ_SCORE = 'FETCH_UNIQ_SCORE';
export const CLEAR_STATE = 'clearState';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SELECTED_GENE = 'SELECTED_GENE';
export const MANY_SELECTED_GENES = 'MANY_SELECTED_GENES';
export const UNSELECTED_GENE = 'UNSELECTED_GENE';
export const MANY_UNSELECTED_GENES = 'MANY_UNSELECTED_GENES';
export const SHOW_GENE_PROFILES = 'SHOW_GENE_PROFILES';
export const FETCH_GENE_PROFILE = 'FETCH_GENE_PROFILE';
export const COMPARE_WITH = 'COMPARE_WITH';
export const GET_COMPARED_DATA = 'GET_COMPARED_DATA';
export const REMOVE_COMPARED_DATA = 'REMOVE_COMPARED_DATA';
export const SET_COLOR_FISHTAIL = 'SET_COLOR_FISHTAIL';
export const SET_SIG_THRESHOLD = 'SET_SIG_THRESHOLD';
export const SHOW_SIG_ONLY = 'SHOW_SIG_ONLY';
export const GET_EXPRESSION_DATA = 'GET_EXPRESSION_DATA';
export const SET_UNIQ_SCORE_UNIVERSE = 'SET_UNIQ_SCORE_UNIVERSE';
export const SET_UNIQ_SCORE_VIZ = 'SET_UNIQ_SCORE_VIZ';

export const DOWNLOAD_DATASET_SCREEN = 'DOWNLOAD_DATASET_SCREEN';
export const FETCH_PUBLIC_MAP_DESCR = 'FETCH_PUBLIC_MAP_DESCR';
export const REMOVE_GENE_PROFILES = 'REMOVE_GENE_PROFILES';
export const SET_SELECTED_GENE = 'SET_SELECTED_GENE';
export const FETCH_GENE_INFO_ENSEMBL = 'FETCH_GENE_INFO_ENSEMBL';
export const REMOVE_GENE_INFO_ENSEMBL = 'REMOVE_GENE_INFO_ENSEMBL';

export const GET_SET_OPERATION = 'GET_SET_OPERATION';
export const UPDATE_SET_OPERATION = 'UPDATE_SET_OPERATION';
export const CLEAR_SET_OPERATION = 'CLEAR_SET_OPERATION';
export const SET_UNIQ_THR_SET_OPERATION = 'SET_UNIQ_THR_SET_OPERATION';

// SYNTHETIC LETHALITY
export const FETCH_SLI_SCREENS = 'FETCH_SLI_SCREENS';
export const GET_UNIQ_SCORE_SLI = 'GET_UNIQ_SCORE_SLI';
export const SET_NAME_SELECTED_SLI_SCREEN = 'SET_NAME_SELECTED_SLI_SCREEN';
export const FETCH_SLI_CONTROLS = 'FETCH_SLI_CONTROLS';
export const SET_NAME_SELECTED_SLI_CONTROL = 'SET_NAME_SELECTED_SLI_CONTROL';
export const FETCH_SLI_SCREENS_DATA = 'FETCH_SLI_SCREENS_DATA';
export const FETCH_SLI_CONTROLS_DATA = 'FETCH_SLI_CONTROLS_DATA';
export const SELECTED_SLI_GENE = 'SELECTED_SLI_GENE';
export const MANY_SELECTED_SLI_GENES = 'MANY_SELECTED_SLI_GENES';
export const UNSELECTED_SLI_GENE = 'UNSELECTED_SLI_GENE';
export const MANY_UNSELECTED_SLI_GENES = 'MANY_UNSELECTED_SLI_GENES';
export const SET_FILTER_ON_FISHER = 'SET_FILTER_ON_FISHER';
export const SET_SIG_THRESHOLD_SLI = 'SET_SIG_THRESHOLD_SLI';
export const SET_MIN_N_CTR = 'SET_MIN_N_CTR';
export const SET_SPECIAL_QUERY = 'SET_SPECIAL_QUERY';
export const FETCH_SLI_FISHER_TABLE = 'FETCH_SLI_FISHER_TABLE';

export const DOWNLOAD_DATASET_SLI = 'DOWNLOAD_DATASET_SLI';
export const SET_SLI_RESULT_VIEW = 'SET_SLI_RESULT_VIEW';
export const FETCH_GENE_INFO_ENSEMBL_SLI = 'FETCH_GENE_INFO_ENSEMBL_SLI';
export const SET_SELECTED_GENE_SLI = 'SET_SELECTED_GENE_SLI';
export const REMOVE_GENE_INFO_ENSEMBL_SLI = 'REMOVE_GENE_INFO_ENSEMBL_SLI';
export const FETCH_GENE_PROFILE_SLI = 'FETCH_GENE_PROFILE_SLI';

export const GET_ESSENTIAL_GENES = 'GET_ESSENTIAL_GENES';
